@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Creepster&family=Inconsolata:wght@500&family=Shrikhand&display=swap');



body {
    margin: 0;
    font-family: 'Creepster', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {

    font-family: 'Creepster', cursive;
}


