/* App.css */

:root {
    --backgroundPrimary: #FDB8E5;
    --backgroundSecondary: #DAF7A6;
    --onBackground: #c5dcab;
    --textColor: #000000;
    --highlight: #FFFFFF;
}


body {
    color: var(--textColor); /*Text Color*/
    background-color: var(--backgroundPrimary);
}
.logo-fade {
    opacity: 1;
    animation: flow-in 1.5s 0s;
    transition: opacity 1000ms ease-in, transform 1000ms ease-in;
}
.page-enter {
    opacity: 0;
}

.page-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}
.page-exit {
    opacity: 0;
    display: none;
}

.page-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
}

/* Navbar Style */
nav {
    background-color: var(--backgroundSecondary);
    height: 64px;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100%;
    z-index: 999;
}

.navbar-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.nav-logo {
    height: 100%;
    position: absolute;
    left: 16px;
    display: flex;
    align-items: center;
}


@media (hover: hover) and (pointer: fine) {
    .hover-jiggle img:hover {
        animation: tilt-shaking 0.5s infinite;
    }
}

.nav-menu {
    width: 100%;
    margin: 0 16px;
    display: flex;
    justify-content: center;
    align-self: center;

}

.nav-menu ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-self: center;
}

.nav-link {
    text-decoration: none;
    color: var(--textColor);
    transition: color 0.3s;
    margin-right: 20px;
}

.nav-menu-collapse {
    margin: 0;
    width: 100%;
}

.hamburger {
    display: none; /* Hide the hamburger icon by default on larger screens */
    align-self: center;
    position: absolute;
    right: 16px;
}

.hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--textColor);
    margin: 5px 0;
}

@media (max-width: 648px) {
    /* Media query for mobile screens */
    .nav-menu-collapse {
        display: none; /* Hide the menu items by default on smaller screens */
    }

    .nav-menu-collapse.open {
        display: flex; /* Show the menu items when the menu is open */
        flex-direction: column;
        position: absolute;
        top: 63px;
        left: 0;
        background-color: var(--onBackground);
        border-bottom: 4px solid var(--backgroundSecondary);
        border-left: 4px solid var(--backgroundSecondary);
        border-right: 4px solid var(--backgroundSecondary);
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        padding: 16px;
        width: 100%;
        box-sizing: border-box;
    }

    .nav-menu-collapse.open li {
        margin: 8px 0;
    }

    .hamburger {
        display: block; /* Show the hamburger icon on smaller screens */
        cursor: pointer;
        float: right
    }
}


/* Main Style */

p {
    max-width: 1024px;
}

.justify-paragraphs p {
    text-align: left;
}

.small-bottom-margin {
    margin-bottom: 4px;
}
main
 {
    height: fit-content;
    margin: 64px 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.particle-background {
    position: sticky;
    z-index: -1;
}

/* Footer Style */

footer {
    position: fixed;
    height: 24px;
    width: 100%;
    max-width: 100vw;
    bottom: 0;
    background-color: var(--backgroundSecondary);
}

.footer-container {
    display: flex;
    height: 100%;
    opacity: 0.75;
    padding: 0 16px;
    align-items: center;
}

.footer-container small {
    font-size: 8px;
}

.footer-container img {
    height: 50%;
    aspect-ratio: 1;
    margin-right: 4px;
    filter: sepia(100%) saturate(250%) brightness(75%) hue-rotate(270deg);
}

.insta-link, .footer-link {
    text-decoration: none;
    color: var(--textColor);
    font-size: 8px;
}

.footer-divider {
    height: 8px;
    width: 1px; /* Adjust the width of the divider */
    background-color: var(--textColor); /* Adjust the color of the divider */
    margin: 0 6px; /* Adjust the margin around the divider */
}

.form-container {
    max-width: 400px;
    margin: 0 auto;
}

.form-container label {
    display: block;
    margin-bottom: 10px;
}

.form-container input, textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--backgroundSecondary);
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    resize: none;
}

.form-container textarea {
    aspect-ratio: 2;
}

button {
    padding: 10px 20px;
    background-color: var(--backgroundSecondary);
    color: var(--textColor);
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.image-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 256px);
    grid-gap: 16px;
    margin: 16px auto;
    justify-content: center;
}



#instafeed img, video,
.grid-image {
    aspect-ratio: 1;
    height: 256px;
    border-radius: 24px;
    border: 4px solid var(--backgroundSecondary);
    background-color: var(--highlight);
    box-sizing: border-box;
    align-self: center;
    transition: transform .5s ease-in-out;
}


.fullscreen-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fullscreen-image img {
    width: 90%;
    border-radius: 8px;
    max-width: 512px;
    max-height: 512px;
}


.insta-footer {
    display: flex;
    height: 64px;
    justify-content: center;
    opacity: .5;
}

.insta-link {
    font-size: 24px;
}

.insta-footer img {
    height: 32px;
    margin-right: 8px;
    transition: all .2s ease-in-out;
}

.input-age {
    width: 64px;
}

/* Animations */

@media (hover: hover) and (pointer: fine) {
    .insta-link:hover, .footer-link:hover, .nav-link:hover {
        color: var(--highlight);
    }

    .hamburger:hover span {
        background-color: var(--highlight);

    }

    .grid-image:hover, #instafeed img:hover,#instafeed video:hover {
        transform: scale(1.1);
    }

}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(12deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-12deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
@keyframes flow-in {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0);
    }
}